import React, { useState } from "react"

import { graphql, useStaticQuery } from "gatsby"

import { useTranslation, Link, FormatDate } from "../i18n"

import GridLayout from "../Layout/GridLayout"
import { Image, Icon, ImageData, Button } from "../Layout/Components"
import { Popover } from "antd"
import { TestimonialData } from "../rows/Testimonials"
import FAQ from "../rows/FAQ"
import { FaqData } from "../rows/FAQ"

import { Seo } from "../seo/"

type Props = {
  data: {
    wordPress: {
      faqCategories: {
        nodes: [
          {
            name
            details: {
              icon
              iconStyle
              iconType
            }
            faqs: {
              nodes: FaqData[]
            }
          }
        ]
      }
    }
  }
}

const faq: React.FC<Props> = ({
  data: {
    wordPress: {
      faqCategories: { nodes: faqCategories },
    },
  },
}) => {
  const { t } = useTranslation([])

  const [activeFaq, setActiveFaq] = useState(0)

  return (
    <GridLayout>
      <Seo title={t("Domande Frequenti")} />
      <div className="row row--center mt-m pb-s">
        <div className="col--12">
          <h1>{t("Rispondiamo a tutte le vostre domande")}</h1>
        </div>
      </div>
      <div className="row row--center row--no-grid pt-none pb-s">
        <div className="faqs-categories">
          {faqCategories.map((faqCategory, i) => (
            <div
              className={"faqs-categories-button" + (activeFaq == i ? " faqs-categories-button--active" : "")}
              onClick={() => {
                setActiveFaq(i)
              }}
            >
              <Icon icon={faqCategory.details} />
              {faqCategory.name}
            </div>
          ))}
        </div>
      </div>
      <FAQ faqs={faqCategories[activeFaq].faqs.nodes} className="pt-none" />
    </GridLayout>
  )
}

export const query = graphql`
  query FaqsQuery {
    wordPress {
      faqCategories(where: { orderby: DESCRIPTION }) {
        nodes {
          name
          details {
            icon
            iconStyle
            iconType
          }
          faqs(where: { orderby: { field: DATE, order: ASC } }, first: 1000) {
            nodes {
              title
              details {
                answer
              }
            }
          }
        }
      }
    }
  }
`

export default faq
